import React from 'react';
import propTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { LineWave } from 'react-loader-spinner';

function GlobalLoader({ height }) {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  return (
    <Box
      className="d-flex align-items-center justify-content-center w-100"
      sx={{ minHeight: '250px', height: `${height}vh` }}
    >
      <LineWave color={primary} width="100" visible />
    </Box>
  );
}

GlobalLoader.propTypes = {
  height: propTypes.number,
};

GlobalLoader.defaultProps = {
  height: 100,
};

export default GlobalLoader;
