import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuthInfo from 'customHooks/useAuthInfo';

function PublicRoutes() {
  const { state } = useLocation();
  const { isAuthenticated } = useAuthInfo();

  if (isAuthenticated) {
    return <Navigate to={state?.from || '/'} replace />;
  }

  return <Outlet />;
}

export default PublicRoutes;
